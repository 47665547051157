export const company =
{
    name: 'RE/MAX Executive Homes',
    welcomeName: 'BuyInBangkok.com',
    heroTitle: 'Your Koh Samui Property Solution',
    mainServiceArea: 'Koh Samui',
    phone1: '+66 2-391-8645 (Business Hours)',
    phone2: '+66 81-823-0785 (After Hours)',
    email: 'sales@executive-homes.biz',
    address1: 'Unit 3A, KC Court, Thong Lo 13',
    address2: 'Bangkok, Thailand 10110',
    phoneUrl: 'tel:0800410420',
    lineUrl: 'https://page.line.me/lkw3948e',
    whatsappUrl: '',
    youtubeUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    tiktokUrl: ''
}
